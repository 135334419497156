/**
 * External Imports
 */
import {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react';
import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
/**
 * Internal Imports
 */
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import { useDebounceValue } from '../../../utils/debouncers';
import { formatRoundingType, toTitleCase } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import {
  CleanQuestion,
  QuestionList,
  QuestionOptionProp,
  QuestionPartProp,
  RootState
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import { PaginationContainer } from '../encountersTable/EncountersTable.styles';
import { Link } from './QuestionBankTable.styles';
import { useUserRolesRef } from '../../../services/UserRoles';

interface QuestionBankTableProps {
  questionsList: QuestionList[];
  searchTerm: string;
  handleCsvData: Dispatch<SetStateAction<CleanQuestion[]>>;
  editQuestion: (id: string) => void;
}
const QuestionBankTable: FC<QuestionBankTableProps> = ({
  questionsList,
  searchTerm,
  handleCsvData,
  editQuestion
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [ptr, setPtr] = useState<CleanQuestion[]>([]);
  const [filteredPtr, setFilteredPtr] = useState<CleanQuestion[]>([]);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('');
  const [active, setActive] = useState<string>('Patient');

  const { isEnterpriseAdmin } = useUserRolesRef();

  // debouncing search terms
  const debouncedQuestionSearchTerm = useDebounceValue(searchTerm, 275);

  const onClickTitle = (id?: string) => {
    if (id) {
      editQuestion(id);
    }
  };

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const sortedPtr = useMemo(() => {
    const stateCopy = [...filteredPtr];
    const sorted = stateCopy.sort((a: CleanQuestion, b: CleanQuestion) => {
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredPtr, sortDir, sortKey]);

  const questionList = useMemo(() => {
    return sortedPtr.slice(pageNumber * 50 - 50, pageNumber * 50);
  }, [pageNumber, sortedPtr]);

  useEffect(() => {
    if (questionsList?.length) {
      const cleanData = questionsList?.map((question: QuestionList) => {
        const questionParts = question?.questionParts.map(
          (part: QuestionPartProp) => {
            return part.question.replace(/"/g, '""').replace(/[\u2028]/g, ' ');
          }
        );
        const answers = question?.questionParts.map(
          (part: QuestionPartProp) => {
            return part.options.flat();
          }
        );
        const ans = [
          ...new Set(
            answers
              .flat()
              .map((opt: QuestionOptionProp) => opt.value?.replace(/"/g, ''))
          )
        ];

        return {
          questionTitle: toTitleCase(question?.questionTitle),
          questionParts: questionParts ? questionParts.join(' - ') : '',
          answers: ans.join('; '),
          questionTags: question?.questionTags?.join(', '),
          roundingType: formatRoundingType(question?.roundingType ?? ''),
          archived: question?.archived,
          questionId: question?.questionId
        };
      });

      setFilteredPtr(cleanData);
      setPtr(cleanData);
      handleCsvData(cleanData);
    }
  }, [questionsList]);

  useEffect(() => {
    // this has to be any to work correctly
    const searchResult = ptr.filter(
      (o: any) =>
        o.questionTitle
          ?.toLowerCase()
          .includes(debouncedQuestionSearchTerm.toLowerCase()) ||
        o.questionParts
          ?.toLowerCase()
          .includes(debouncedQuestionSearchTerm.toLowerCase()) ||
        o.questionTags
          ?.toLowerCase()
          .includes(debouncedQuestionSearchTerm.toLowerCase())
    );
    setFilteredPtr(searchResult);
    handleCsvData(searchResult);
  }, [ptr, debouncedQuestionSearchTerm]);

  useEffect(() => {
    handleCsvData(sortedPtr);
  }, [sortedPtr]);

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Title': '20%', 'Parts': '30%', 'Answers': '15%', 'Tags': '15%', 'Type': '10%', 'Archived': '10%'}"
        header
      >
        <NeuTableHeading
          slot="Title"
          icon={
            !(active === 'Title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('questionTitle', 'Title')}
          active={active === 'Title'}
        >
          Title
        </NeuTableHeading>
        <NeuTableHeading
          slot="Parts"
          icon="none"
          // onClick={() => sortByHeading('questionParts', 'Parts')}
          // active={active === 'Parts'}
        >
          Parts
        </NeuTableHeading>
        <NeuTableHeading slot="Answers" icon="none" onClick={() => {}}>
          Answers
        </NeuTableHeading>
        <NeuTableHeading
          slot="Tags"
          icon="none"
          // onClick={() => sortByHeading('questionTags', 'Parts')}
          // active={active === 'Parts'}
        >
          Tags
        </NeuTableHeading>
        <NeuTableHeading
          slot="Type"
          icon={
            !(active === 'Type') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('roundingType', 'Type')}
          active={active === 'Type'}
        >
          Type
        </NeuTableHeading>
        <NeuTableHeading
          slot="Archived"
          icon={!(active === 'Archived') ? 'asc' : sortDir}
          onClick={() => sortByHeading('archived', 'Archived')}
          active={active === 'Archived'}
        >
          Archived
        </NeuTableHeading>
      </NeuTableRow>
      {!questionList ||
      (questionList.length === 0 && !debouncedQuestionSearchTerm) ? (
        <NoDataSplashView type="questionBank" />
      ) : questionList.length === 0 && debouncedQuestionSearchTerm ? (
        <NoDataSplashView type="questionBankSearch" />
      ) : (
        <>
          <NeuTableBody>
            {questionList?.map((question: CleanQuestion) => {
              return (
                <NeuTableRow
                  columns="{'Title': '20%', 'Parts': '30%', 'Answers': '15%', 'Tags': '15%', 'Type': '10%', 'Archived': '10%'}"
                  size="large"
                  key={question.questionId}
                >
                  <NeuLabel
                    style={{ whiteSpace: 'normal' }}
                    slot="Title"
                    color={isEnterpriseAdmin ? 'primary' : 'plain-100'}
                  >
                    <Link
                      onClick={
                        isEnterpriseAdmin
                          ? () => onClickTitle(question.questionId)
                          : () => {}
                      }
                      href="/"
                    >
                      {question.questionTitle}
                    </Link>
                  </NeuLabel>
                  <NeuLabel style={{ whiteSpace: 'normal' }} slot="Parts">
                    <div>
                      {question.questionParts
                        .replace(/[\u2028]/g, ' ')
                        .replace(/""/g, '"')}
                    </div>
                  </NeuLabel>
                  <NeuLabel style={{ whiteSpace: 'normal' }} slot="Answers">
                    <div>{question.answers}</div>
                  </NeuLabel>
                  <NeuLabel slot="Tags" style={{ whiteSpace: 'normal' }}>
                    {question.questionTags}
                  </NeuLabel>
                  <NeuLabel slot="Type">
                    {question.roundingType
                      ? formatRoundingType(question.roundingType)
                      : 'Unknown'}
                  </NeuLabel>
                  <NeuLabel slot="Archived">
                    {toTitleCase(question.archived?.toString())}
                  </NeuLabel>
                </NeuTableRow>
              );
            })}
          </NeuTableBody>
          <PaginationContainer>
            <NeuPaginator
              total={filteredPtr?.length || 1}
              rowsPerPage={50}
              onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
            />
          </PaginationContainer>
        </>
      )}
    </NeuTable>
  );
};

export default QuestionBankTable;
