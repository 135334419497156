import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Loading from '../../components/shared/Loading';
import Login from '../login/Login';
import {
  openModal,
  setModalType,
  setModalData
} from '../../redux/actions/Modal.action';
import { getSingleIssueOrFeedbackFromEmail } from '../../redux/actions/Task.action';
import { handleLastPath } from '../../utils/lastPath';
import { IFacility, RootState, Task } from '../../config/interfaces';
import { setUserSectionType } from '../../redux/actions/User.action';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';

interface RouteProps {
  component?: JSX.Element;
  authUserRoles: Array<string>;
  facilities: IFacility[];
  isAuthorized: string;
  serviceCentralCategories: string[];
  singleTaskToComplete: Array<Task>;
  userFacilityId: string;
}

const ProtectedRoute = ({
  component,
  authUserRoles,
  facilities,
  isAuthorized,
  serviceCentralCategories,
  singleTaskToComplete,
  userFacilityId
}: RouteProps) => {
  const dispatch = useDispatch();
  const { isAllNonSupportRounder, isEnterpriseAdmin } = useUserRolesRef();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const localStoragePath = window.localStorage.getItem('task');
  const isCompleteTask =
    localStoragePath?.includes('task/complete') ||
    pathname.includes('task/complete');
  const taskIdFromLocalStorage = localStoragePath?.split('i=')[1]
    ? localStoragePath?.split('i=')[1]
    : search.split('i=')[1];

  const userFacility = useMemo(
    () =>
      facilities.filter(facility => facility.id === userFacilityId).length > 0
        ? facilities.filter(facility => facility.id === userFacilityId)[0]
        : facilities[0],
    [facilities, userFacilityId]
  );

  // render login component when not prod environment else loading component
  const Fallback = useMemo(() => {
    if (
      code ||
      process.env.REACT_APP_ENV === 'production' ||
      pathname.includes('task/complete')
    ) {
      return <Loading />;
    }
    return <Login />;
  }, [code, pathname]);

  useEffect(() => {
    if (isCompleteTask && isAuthorized && userFacility && authUserRoles) {
      // If complete task from user email, fetch task for modal
      if (!(isEnterpriseAdmin && isAllNonSupportRounder)) {
        // if user is employeeRounder do not show them patient Issue data
        navigate('/404');
      } else {
        dispatch(getSingleIssueOrFeedbackFromEmail(taskIdFromLocalStorage));
      }
    }
    if (isAuthorized && userFacility && code && authUserRoles) {
      handleLastPath(authUserRoles, navigate);
    }
  }, [authUserRoles, userFacility]);

  useEffect(() => {
    // get task data and set modal data
    if (singleTaskToComplete.length > 0) {
      if (
        serviceCentralCategories.includes(singleTaskToComplete[0].category) ||
        serviceCentralCategories.includes(singleTaskToComplete[0].taskSubtype)
      ) {
        dispatch(setModalType('View Issue'));
      } else {
        dispatch(setModalType('Edit Issue'));
      }
      dispatch(openModal());
      dispatch(setModalData(singleTaskToComplete[0]));
      dispatch(setUserSectionType(singleTaskToComplete[0].roundingType));
    }
  }, [singleTaskToComplete]);

  /**
   * Check if user is logged in already, else redirect to login page.
   */
  return isAuthorized && userFacility ? (
    <div>
      <Header
        facilityTitle={userFacility.value}
        divisionTitle={userFacility.division}
      />
      {component}
    </div>
  ) : (
    Fallback
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authUserRoles: state.AuthorizedUser.authorizedUser.roles,
  facilities: state.UserReducer.facilities,
  isAuthorized: state.AuthorizedUser.isAuthenticated,
  serviceCentralCategories: state.ConfigReducer.serviceCentralCategories,
  singleTaskToComplete: state.TaskReducer.singleTaskToComplete,
  userFacilityId: state.AuthorizedUser.authorizedUser.facilityId
});

export default connect(mapReduxStateToProps)(ProtectedRoute);
