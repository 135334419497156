import { RootState } from '../../config/interfaces';
import { useAppSelector } from '../../redux/store';

export const useUserRolesRef: () => any = () =>
  useAppSelector((state: RootState) => {
    const { authRoles } = state.AuthorizedUser;
    const hasRole = (role: string) =>
      authRoles.includes(role) || authRoles.includes('enterpriseAdmin');
    return {
      isEnterpriseAdmin: hasRole('enterpriseAdmin'),
      isUserAdmin: hasRole('userAdmin'),
      isTemplateConfigurator: hasRole('templateConfigurator'),
      isPatientRounder: hasRole('patientRounder'),
      isEmployeeRounder: hasRole('employeeRounder'),
      isCNEdRounder: hasRole('cnedRounder'),
      isCSRNRounder: hasRole('csrnRounder'),
      isSupportRounder: hasRole('cnedRounder') || hasRole('csrnRounder'),
      isAllSupportRounder: hasRole('cnedRounder') && hasRole('csrnRounder'),
      isMultipleRounders: authRoles.length > 1,
      isOnlyTwoTypeRounders: authRoles.length === 2,
      isNonSupportRounder:
        hasRole('patientRounder') || hasRole('employeeRounder'),
      isAllNonSupportRounder:
        hasRole('patientRounder') && hasRole('employeeRounder')
    };
  });
