import { RootState } from '../../config/interfaces';
import { useAppSelector } from '../../redux/store';

export const useFeatures: () => any = () =>
  useAppSelector((state: RootState) => {
    return {
      isCSRNEnabled: state.FeatureReducer.isCSRNEnabled,
      isCNEdEnabled: state.FeatureReducer.isCNEdEnabled
    };
  });
